hr {
    background-color: #888888;
}

@media (max-width: 700px) {
    col.col1 {
        width: 50%;
    }
}

@media (min-width: 700px) {
    col.col1 {
        width: 15%;
    }
}